body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  margin: 0;
  padding: 0;
}

.no-list {
  list-style: none;
  padding: 0;
}

.with-background {
  background-image: url("./receipt@3x.png");
  background-repeat: repeat;
  background-size: 5px 5px;
}

.is-stretched {
  min-height: 100vh;
}

.is-keyboard-accessory {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 5px 15px;
  background: #fff;
}

.is-visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
