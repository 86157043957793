.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  text-align: center;

  width: 320px;
  min-height: 300px;

  margin: 0 auto;
  padding: 20px 0;

  background: url("./reciept-paper@1x.png") no-repeat bottom center;
}

@media (-webkit-min-device-pixel-ratio: 2) {
  .container {
    background-image: url("./reciept-paper@2x.png");
  }
}

@media (-webkit-min-device-pixel-ratio: 3) {
  .container {
    background-image: url("./reciept-paper@3x.png");
  }
}

.inner {
  padding: 0 15px;
}

.slabText {
  font-family: Bebas-Neue, sans-serif;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 0.9;
}
